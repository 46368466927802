<template>
<div>
    <div class="cs-preloader cs-white_bg cs-center" style="display:none">
        <div class="cs-preloader_in">
            <img :src="require(`@/assets/img/logo_mini.svg`)" alt="Logo">
        </div>
    </div>
    <Header />
    <router-view />
    <Footer />
</div>
</template>
<script>
import Header from './Header.vue'
import Footer from './Footer.vue'

import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
    name: 'HomeLayout',
    components: {
        Header,
        Footer
    },
    created() {
      AOS.init()
      window.addEventListener("load", function() {
        AOS.init()
      });
    },
}
</script>
<style lang="css" scoped>
    @import '../../assets/css/aos.css';
</style>